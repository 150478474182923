

import Vue from 'vue';
import "./css/main.scss";
import Components from "./components";
import VueAwesomeSwiper from 'vue-awesome-swiper'
import axios from 'axios';
import EventBus from './EventBus'
import $ from 'jquery';

require('jquery-serializejson');
import cart from './modules/cart';
cart();
import product from './modules/product';
import Slide from 'vue-burger-menu'

import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'
// import style
import 'swiper/css/swiper.css'

Vue.use(VueAwesomeSwiper)
// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty('--vh', `${vh}px`);


if ($("#app").length) {
  new Vue({
    el: "#app",
    delimiters: ["[[", "]]"],
    components: {
     LocalSwiper: VueAwesomeSwiper.swiper,
     LocalSlide: VueAwesomeSwiper.swiperSlide,
	 RangeSlider
   },
  // props: ['minHeight'],

   data() {
    return {
	  sliderVal:(this.sliderVal*1)||0,
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
      couponError: '',
      cartOpen: false,
      cartSummary: false,
      cart: window.cart || {
        numberOfItems: 0,
        totalPrice: '',
        items: {}
      },
      cartLoading: false,
      cartAddressForm: {
          billingAddressSameAsShipping: true
      },
      swiperOptionTop: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionTop: {
        //loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperOptionThumbs: {
        //loop: true,
        loopedSlides: 5, // looped slides should be the same
        spaceBetween: 0,
        dynamicBullets:true,
        centeredSlides: true,
        slidesPerView: 'auto',
        touchRatio: 0.2,
        slideToClickedSlide: true,
        breakpoints:{
          600: {
             spaceBetween: 10,
             dynamicBullets:false,
     },
        }
      },
      swiperOptionCollection: {
        spaceBetween: 10,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      },
      swiperOptionFeatured: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        }
      },
      swiperCheckoutMeter:{
        slidesPerView: "auto",
        spaceBetween: 1,
        grabCursor: true,
        centeredSlides: true
      }
    }
  },
  created() {
    this.cartAddress();

  },
  mounted() {



    if (typeof this.$refs.swiperTop !== 'undefined') {
      this.$nextTick(() => {
        const swiperTop = this.$refs.swiperTop.$swiper
        const swiperThumbs = this.$refs.swiperThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop
        product(swiperTop, swiperThumbs);
      })
    }

    this.cartUpdate();

  },
  beforeMount: function() {
    //this.sliderVal = this.$el.attributes['data-name'].value;
	if($('#minHeight').length)
	{
		this.sliderVal = $('#minHeight').val();
	}
  },
  methods: {


	changeHeight() {
		console.log("hi");
		console.log(this.sliderVal);
		this.$emit('changeHeight', this.sliderVal);
	},
	addToCartFromForm() {
		const purchasableId = $(this.$refs.purchasable).val();
		const qty = $(this.$refs.quantity).val();
		this.addToCart(purchasableId, qty);
	},

    addToCart(purchasableId, qty) {
      /**
       * USAGE:
       *
       *   <a href="#" @click.prevent="addToCart(7, 1)">Add To Cart</a>
       */
	   //const optionHeight = $(this.$refs.optionHeight).val();
	  // const optionAluminiumColour = $(this.$refs.optionHeight).val();
	   const allOptions = {};
	   //if (typeof this.$refs.optionHeight !== 'undefined') {
		 // allOptions["optionHeight"] = $(this.$refs.optionHeight).val();
	   //}

        if (typeof this.sliderVal !== 'undefined') {
		 allOptions["optionHeight"] = this.sliderVal;
	   }

	   if (typeof this.$refs.optionAluminiumColour !== 'undefined') {
		  allOptions["optionAluminiumColour"] = $(this.$refs.optionAluminiumColour).val();
	   }

       if (typeof this.$refs.optionVariantMaterial !== 'undefined') {
           console.log("Door material option", this.$refs.optionVariantMaterial, this.$refs.optionVariantMaterial.value)
           allOptions["OptionDoorMaterial"] = this.$refs.optionVariantMaterial.value;
       }

		if (typeof this.$refs.OptionEndConfiguration !== 'undefined') {
			 // $('input[name="OptionEndConfiguration"]').filter(":checked").data('optionValue');
			allOptions["OptionEndConfiguration"] = $('input[name="OptionEndConfiguration"]').filter(":checked").val();
		}

    if (typeof this.$refs.OptionLidConfiguration !== 'undefined') {
       // $('input[name="OptionEndConfiguration"]').filter(":checked").data('optionValue');
      allOptions["OptionLidConfiguration"] = $('input[name="OptionLidConfiguration"]').filter(":checked").val();
    }
		/*var customprice =0;
		if($('[data-variant-price-full-withoutcurrency]').length){
			customprice =$('[data-variant-price-full-withoutcurrency]').text()*1;
		}	*/

	   //console.log(allOptions);

      const _this = this; // 'customprice': customprice,
     // alert("working200");
      axios.post(window.siteUrl + `/actions/commerce/cart/update-cart`, {
        'purchasableId': purchasableId,
		'options' : allOptions,
        'qty': qty,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });

      EventBus.$emit('addToCart');
    },
    addToCartForm(formId) {
      /**
       * USAGE:
       *
       *   <form id="form-id" v-on:submit.prevent="addToCartForm('form-id')">
       *     <input type="hidden" name="qty" value="1">
       *     <input type="hidden" name="purchasableId" value="{{ product.defaultVariant.id }}">
       *     <button type="submit">Add to cart</button>
       *   </form>
       */
      const _this = this;

      const values = $('#'+formId).serializeJSON();

      axios.post(window.siteUrl + `/actions/commerce/cart/update-cart`, {
        'purchasableId': values.purchasableId,
        'qty': values.qty,
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartOpen = true;
      })
      .catch(function (error) {
        console.log(error);
      });
    },
    cartUpdateProductQuantity(id, qty) {
      const _this = this;

      _this.cartLoading = true;
      axios.post(window.siteUrl + `/actions/commerce/cart/update-cart`, {
        'lineItems': {[id]: {'qty': qty}},
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cartUpdate();
      })
      .catch(function (error) {
        console.log(error);
        _this.cartLoading = false;
      });
    },
    cartUpdateProductNote(id, note) {

      this.cartLoading = true;
      axios.post(window.siteUrl + `/actions/commerce/cart/update-cart`, {
        'lineItems': {[id]: {'note': note}},
        [this.csrfName]: this.csrfToken
      })
      .then((response) =>{
        this.cartUpdate();
      })
      .catch((error) => {
        console.log(error);
        this.cartLoading = false;
      });
    },
    cartUpdateCouponCode(code) {

      this.cartLoading = true;
      axios.post(window.siteUrl + `/actions/commerce/cart/update-cart`, {
        'couponCode': code,
        [this.csrfName]: this.csrfToken
      })
      .then((response) => {
          if (response.data.error) {
              this.couponError = response.data.errors.couponCode && response.data.errors.couponCode[0]
          } else {
              this.couponError = ''
          }
        this.cartUpdate();
      })
      .catch((error) => {
        console.log(error);
        this.cartLoading = false;
      });
    },
    openVariants(id) {
      document.getElementById(id).classList.toggle('open');
    },
    cartUpdate() {
      const _this = this;
      console.log('Updating cart');

      _this.cartLoading = true;
      axios.get(window.siteUrl + `/shop/cart.json`, {
        [this.csrfName]: this.csrfToken
      })
      .then(function (response) {
        _this.cart = response.data;
      })
      .catch(function (error) {
        console.log(error);
      }).then(function() {
        _this.cartLoading = false;
      });

      // Refresh cart in case it got updated in different tab
      clearTimeout(window.cartRegularUpdate);
      window.cartRegularUpdate = setTimeout(this.cartUpdate, 120000);
    },
    cartAddress() {
      // init
      if (document.getElementsByName('billingAddressSameAsShipping').length) {
        let element = document.getElementsByName('billingAddressSameAsShipping')[0];
        this.cartAddressForm.billingAddressSameAsShipping = element.hasAttribute('checked');
      }
    }
  },
  watch: {
    cartOpen(value) {
      if (value == true) {
        this.cartUpdate();
      }
    }
  }
  });
}



// $( ".purchasableId" ).change(function(e) {
//   $(".purchasableInfo").hide();
//   $("#"+($(this).find(":selected").data('info'))).toggle();
// });
