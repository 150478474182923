<template>
<Slide noOverlay right :crossIcon="true">
  <ul class="list-reset flex flex-col justify-start font-sm items-start font-medium normal-case text-left">
    <li class="my-4 w-64" v-for="node in mainNodes">
      <a class="my-6 text-white" :href="node.url">
        {{node.title}}
      </a>
    </li>

  </ul>
</Slide>
</template>
<style media="screen">
.bm-burger-button {
  top: 16px !important;
  right: 12px !important;
}

.line-style {
  height: 10% !important;

}

.bm-menu {
  background-color: #D35B57 !important;

}

.bm-cross-button {
  top: 20px !important;
  right: 20px !important;
}

.bm-cross {
  height: 40px !important;
  background-color: #fff !important;
}
</style>
<script>
import {
  Slide
} from 'vue-burger-menu' // import the CSS transitions you wish to use, in this case we are using `Slide`

export default {
  props: {
    mainNodes: Array,
  },
  components: {
    Slide // Register your component
  }
}
</script>
