<template>
<article>
  <a :href="product.url">
    <div class="mx-4 mb-4">
      <div class="w-full relative" style="min-height:200px;">
        <div class="w-full placeholder absolute" style="z-index:-1;margin-bottom:100%;">
          <div class=" h-48 bg-gray-200">

          </div>
        </div>
        <img class="relative" :src="product.image">
      </div>
      <div class="flex flex-col justify-between items-center pt-4">
        <div class="flex items-center justify-start">
          <h3 class="mb-1 text-base md:text-base">
            {{ product.title }}
          </h3>
        </div>

        <div class="relative bg-red-800" v-for="variant in product.variants.first">
       {{ variant.price | price }}
        </div>
        <div :id="'product-' + product.id + '-purchasable-' + product.purchasable.id" class="purchasableInfo mt-0">
          <p class="pt-0 text-xl font-bold mb-0 text-gray-700" :class="{'line-through': product.defaultVariant.onSale}">  {{ product.purchasable.price | price }} </p>
          <p class="pt-1 text-xl font-bold mb-0 text-gray-700" v-if="product.defaultVariant.onSale">{{ product.purchasable.salePrice | price }}</p>
          <strong v-if="product.purchasable.sales.length">Sales Applied:</strong><br>
          <ul>
            <li v-for="sale in product.purchasable.sales"><strong>{{ sale.name }}</strong> {{ sale.description }}</li>
          </ul>
        </div>
      </div>

      <div class="relative add-to-cart" v-if="product.variants.length > 1">
       <!-- <button class="bg-white block py-2 border border-gray-700 hover:bg-gray-800 text-center text-gray-700 hover:text-white w-full rounded-none mt-0 leading-normal add-to-cart__toggle" :id="'add-to-cart-' + product.defaultVariant.id"
          @click.prevent="openVariants('add-to-cart-' + product.defaultVariant.id)">Add to cart</button>-->

		  <a :href="product.url" class="bg-white block py-2 border border-gray-700 hover:bg-gray-800 text-center text-gray-700 hover:text-white w-full rounded-none mt-0 leading-normal add-to-cart__toggle">View Details</a>

        <div class="absolute left-0 w-full h-0 overflow-hidden add-to-cart__variants">
          <form method="POST" class="mt-0" v-for="variant in product.variants">
            <input type="hidden" name="action" value="commerce/cart/update-cart">
            <input type="hidden" name="qty" value="1">
            <input type="hidden" :name="csrfName" :value="csrfToken">
            <input type="hidden" name="purchasableId" :value="variant.id">
            <button type="submit" class="bg-white block py-2 border border-gray-700 border-b-0 hover:bg-gray-800 text-gray-700 hover:text-white w-full rounded-none my-0 leading-normal" @click.prevent="addToCart(variant.id, 1)">{{ variant.productSize }}
              - {{ variant.price | price }}</button>

          </form>
        </div>
      </div>
      <div class="add-to-cart" v-else>
        <form method="POST" class="mt-0">
          <input type="hidden" name="action" value="commerce/cart/update-cart">
          <input type="hidden" name="qty" value="1">
          <input type="hidden" :name="csrfName" :value="csrfToken">
          <input type="hidden" name="purchasableId" :value="product.defaultVariant.id">
          <button type="submit" class="bg-white block py-2 border border-gray-700 hover:bg-gray-800 text-center text-gray-700 hover:text-white w-full rounded-none mt-0 leading-normal" @click.prevent="addToCart(product.defaultVariant.id, 1)">Add to
            cart</button>



        </form>
      </div>
    </div>
  </a>
</article>
</template>

<script>
export default {
  name: 'product',
  props: {
    product: {
      type: Object
    }
  },
  data() {
    return {
      csrfName: window.csrfTokenName,
      csrfToken: window.csrfTokenValue,
    }
  },
  filters: {
    price(number) {
      return "$" + parseFloat(number).toFixed(2);
    }
  },
  methods: {
    openVariants(id) {
      document.getElementById(id).classList.toggle('open');
    },
    addToCart(id, qty) {
      this.$emit('add-to-cart', id, qty);
    }
  }
}
</script>
