import $ from 'jquery';

export default function init($swiperTop, $swiperThumb) {

    $( document ).ready(function () {

        console.log("Loading product page handling");

        // Let's get the three selects
        var $sizeSelect = $('select[name="variantSize"]'),
            $materialSelect = $('select[name="variantMaterial"]'),
            $variantSelect = $('select[name="purchasableId"]'),
            $materialRadio = $('span[data-variant-select-material]'),
            $sizeRadio = $('span[data-variant-select-size]'),
            $variantField = $('#variantselector'),
            $currentMaterial, $currentSize,
			$aluminiumColourRadio = $('span[data-variant-select-aluminium-colour]'),
			$aluminiumColourSelect = $('select[name="optionAluminiumColour"]'),
			$currentAluminiumColour,
			$endConfigurationRadio = $('input[name="OptionEndConfiguration"]'),
      $lidConfigurationRadio = $('input[name="OptionLidConfiguration"]'),
      $endConfigurationPrice = 0,
      $lidConfigurationPrice = 0,
      $lidConfigurationChoice = 0,
      $lidConfigurationChoices = [];

        init();

        function init() {
            // If the material select exists, add an on change handler to it
            if ($materialSelect.length > 0) {
                $materialSelect.on('change', function(e) {
                    updateSelectStatus('material');
                });
            }

            // If the size select exists, add an on change handler to it
            if ($sizeSelect.length > 0) {
                $sizeSelect.on('change', function(e) {
                    updateSelectStatus('size');
                    refreshLidConfigurationPrices();
                });
            }

			/* NEW CHANGES 10/2020 STARTS  */

			 $aluminiumColourRadio.on('click', (event) => {
                var elem = $(event.target);
                console.log(`Clicked ${elem.data('optionValue')}`);
                $currentAluminiumColour = elem.data('optionValue');
                $aluminiumColourRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                $aluminiumColourRadio.addClass('border-gray-300');
                elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                elem.removeClass('border-gray-300');

				var $aluminiumColourSelectOptions = $aluminiumColourSelect.find('option');
				var $selectedOption = null;
				var $variantIndex;

				$aluminiumColourSelect.val('');

				$aluminiumColourSelectOptions.each(function(i) {
					if (String($(this).attr('value')) === $currentAluminiumColour ) {
						$aluminiumColourSelect.val($(this).attr('value'));
						//$('#id option[value=theOptionValue]').prop('selected', 'selected')
						//$selectedOption = $(this);
						//$variantIndex = i;
					}
				});






            });

			 if ($endConfigurationRadio.length > 0) {
				$endConfigurationRadio.on('click', (event) => {
					var elem = $(event.target);
					console.log(`Clicked ${$(elem).data('optionValue')}`);
					$endConfigurationPrice = $(elem).data('optionValue');
					increasePriceByValue();
					//alert(amtToBeIncreased);
					//alert(curPrice);
					//alert("boss");
					/*console.log(`Clicked ${$(elem).data('variantValue')}`);
					$currentMaterial = $(elem).data('variantValue');
					$materialRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
					$materialRadio.addClass('border-gray-300');
					elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
					elem.removeClass('border-gray-300');
					selectVariant($currentMaterial, $currentSize);*/
				});
			 }

       if ($lidConfigurationRadio.length > 0) {
        $lidConfigurationRadio.each((index, elem) => {
          $lidConfigurationChoices.push({
            small: $(elem).data('optionValueSmall'),
            large: $(elem).data('optionValueLarge'),
            threshold: $(elem).data('optionValueThreshold')
          })
          $(elem).data('optionIndex', index);
        })
        console.log($lidConfigurationChoices);
				$lidConfigurationRadio.on('click', (event) => {
					var elem = $(event.target);
					console.log(`Clicked ${$(elem).data('optionValue')}`);
          var smallPrice = $(elem).data('optionValueSmall'),
              largePrice = $(elem).data('optionValueLarge'),
              threshold = $(elem).data('optionValueThreshold');

					$lidConfigurationChoice = $(elem).data('optionIndex');
					increasePriceByValue();
					//alert(amtToBeIncreased);
					//alert(curPrice);
					//alert("boss");
					/*console.log(`Clicked ${$(elem).data('variantValue')}`);
					$currentMaterial = $(elem).data('variantValue');
					$materialRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
					$materialRadio.addClass('border-gray-300');
					elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
					elem.removeClass('border-gray-300');
					selectVariant($currentMaterial, $currentSize);*/
				});
			 }

			 /* NEW CHANGES 10/2020 ENDS  */

            $materialRadio.on('click', (event) => {
                var elem = $(event.target);
                console.log(`Clicked ${$(elem).data('variantValue')}`);
                $currentMaterial = $(elem).data('variantValue');
                $materialRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                $materialRadio.addClass('border-gray-300');
                elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                elem.removeClass('border-gray-300');
                selectVariant($currentMaterial, $currentSize);
            });

            $sizeRadio.on('click', (event) => {
                var elem = $(event.target);
                console.log(`Clicked ${elem.data('variantValue')}`);
                $currentSize = elem.data('variantValue');
                $sizeRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                $sizeRadio.addClass('border-gray-300');
                elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                elem.removeClass('border-gray-300');
                selectVariant($currentMaterial, $currentSize);
                refreshLidConfigurationPrices();
            })

            if ($sizeSelect.length > 0 && $materialSelect.length > 0) {
                selectVariant($materialSelect.val(), $sizeSelect.val());
                $currentMaterial = $materialSelect.val();
                $currentSize = $sizeSelect.val();
                $materialRadio.each((i, e) => {
                    var elem = $(e);
                    if (elem.data('variantValue') === $currentMaterial) {
                        elem.removeClass('border-gray-300');
                        elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                    }

                });
                $sizeRadio.each((i, e) => {
                    var elem = $(e);
                    if (elem.data('variantValue') === $currentSize) {
                        elem.removeClass('border-gray-300');
                        elem.addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                    }

                });
                $variantField.toggleClass('hidden');
                refreshLidConfigurationPrices();
            }

            // Disable the materials and sizes that are completely sold out
            disableSoldOutOptions();
            updateSlider(0);
        }

        function materialChangeHandler() {
            $materialRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
            $materialRadio.addClass('border-gray-300');
            $materialRadio.each(function (index, elem) {
                var val = $(elem).data('variantValue');
                if ($currentMaterial === val) {
                    $(elem).addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                    $(elem).removeClass('border-gray-300');
                }
            });
        }

        function sizeChangeHandler() {
            $sizeRadio.removeClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
            $sizeRadio.addClass('border-gray-300');
            $sizeRadio.each(function (index, elem) {
                var val = $(elem).data('variantValue');
                if ($currentSize === val) {
                    $(elem).addClass('border-red-400 bg-red-500 text-white font-bold relative z-20');
                    $(elem).removeClass('border-gray-300');
                }
            });
        }

        /**
         * Triggered when one of the attribute dropdowns is selected.
         */
        function updateSelectStatus(attribute) {
            if ($materialSelect.length > 0 && $sizeSelect.length > 0) {
                if (attribute === 'material') {
                    disableUnavailableOptions('material', $materialSelect.val());
                } else if (attribute === 'size') {
                    disableUnavailableOptions('size', $sizeSelect.val());
                }

                selectVariant($materialSelect.val(), $sizeSelect.val());
            } else {
                if (attribute === 'material') {
                    selectVariant($materialSelect.val(), '');
                } else {
                    selectVariant('', $sizeSelect.val());
                }
            }
        }

        /**
         * Selects a variant based on the value of the two attribute dropdowns
         */
        function selectVariant(material, size) {
            var $variantOptions = $variantSelect.find('option');
            var $selectedOption = null;
            var $variantIndex;

            $variantSelect.val('');

            $variantOptions.each(function(i) {
                if (String($(this).data('material')) === material && String($(this).data('size')) === size) {
                    $variantSelect.val($(this).attr('value'));
                    $selectedOption = $(this);
                    $variantIndex = i;
                }
            });

            updatePrice($selectedOption);
            updateSlider($variantIndex);
        }

        /**
         * Based on the attribute and value the user selected, disable all options
         * in the other dropdown that don't have a variant with this value.
         */
        function disableUnavailableOptions(attribute, val) {
            var $variantOptions = $variantSelect.find('option');
            var reverseAttribute = attribute === 'material' ? 'size' : 'material';

            if (attribute === 'material') {
                $sizeSelect.find('option').removeAttr('disabled');
            } else {
                $materialSelect.find('option').removeAttr('disabled');
            }

            if (val!=='') {
                var available = [];

                $variantOptions.each(function(i) {
                    if ($(this).data(attribute) === val && !$(this).is(':disabled')) {
                        available.push($(this).data(reverseAttribute));
                    }
                });

                var $otherSelect = attribute === 'material' ? $sizeSelect : $materialSelect;

                $otherSelect.find('option').each(function() {
                    var $opt = $(this);
                    if (($.inArray($opt.attr('value'), available) !== -1) || ($opt.attr('value') === '')) {
                        $opt.removeAttr('disabled');
                    } else {
                        $opt.attr('disabled', 'disabled');
                    }
                });
            }

            disableSoldOutOptions();
        }

        /**
         * Disables sold out options. Loops over the two selects and checks if
         * there is a variant in the variants dropdown that is not out of stock.
         */
        function disableSoldOutOptions() {
            var $variantOptions = $variantSelect.find('option');

            if ($materialSelect.length > 0) {
                $materialSelect.find('option').each(function() {
                    var $opt = $(this);
                    var found = false;

                    $variantOptions.each(function(i) {
                        if (($(this).data('out-of-stock') !== true) && (String($(this).data('material')) === $opt.val())) {
                            found = true;
                        }
                    });

                    if (!found) {
                        $opt.attr('disabled', 'disabled');
                    }
                });
            }

            if ($sizeSelect.length > 0) {
                $sizeSelect.find('option').each(function() {
                    var $opt = $(this);
                    var found = false;

                    $variantOptions.each(function(i) {
                        if (($(this).data('out-of-stock') !== true) && (String($(this).data('size')) === $opt.val())) {
                            found = true;
                        }
                    });

                    if (!found) {
                        $opt.attr('disabled', 'disabled');
                    }
                });
            }
        }

        function updateSlider($index) {
          console.log("Updating slider to", $index);
          $swiperTop.slideTo($index);
        }

        /**
         * Updates the price based on the variant that was selected. You'll probably
         * want to do some currency formatting in here, and maybe remove the sale
         * price if it's the same as the full price.
         */
        function updatePrice($option) {

            console.log($option);

            if ($option !== null) {
                var $priceFull = $('[data-variant-price-full]');
                var $priceSale = $('[data-variant-price-sale]');

				var $displayCurrency = $('[data-variant-display-currency]');
				var $priceFullWithoutcurrency = $('[data-variant-price-full-withoutcurrency]');
				var $priceFullWcOrigianl = $('[data-variant-price-full-wc-original]');

				if ($priceFullWithoutcurrency.length > 0) {
                    $priceFullWithoutcurrency.text($option.data('price-withoutcurrency'));
                }

				if ($priceFullWcOrigianl.length > 0) {
                    $priceFullWcOrigianl.text($option.data('price-withoutcurrency'));
                }

                if ($priceFull.length > 0) {
                  /*
                  var totalAddAmount = 0;

                  if ($endConfigurationRadio.is(':checked') && $endConfigurationRadio.val() != '') {
                    totalAddAmount += $endConfigurationRadio.filter(":checked").data('optionValue');
                  }

                  if ($lidConfigurationRadio.is(':checked') && $lidConfigurationRadio.val() != '') {
                    totalAddAmount += $lidConfigurationRadio.filter(":checked").data('optionValue');
                  }*/

					increasePriceByValue();
						//$priceFull.text(formatPrice($option.data('price')));


                }

                if ($priceSale.length > 0) {
                    $priceSale.text(formatPrice($option.data('sale-price')));
                }
            }
        }

		function increasePriceByValue() {
      var choice = $lidConfigurationChoices[$lidConfigurationChoice];
      $lidConfigurationPrice = (parseInt($currentSize) >= choice.threshold ? choice.large : choice.small);
      var amtToBeIncreased = $endConfigurationPrice + $lidConfigurationPrice;
			if (amtToBeIncreased !== null) {
                var $priceFull = $('[data-variant-price-full]');
                var $priceSale = $('[data-variant-price-sale]');
				var $displayCurrency = $('[data-variant-display-currency]');
				var $priceFullWithoutcurrency = $('[data-variant-price-full-withoutcurrency]');
                var curPrice = getCurrentPrice();
			    //alert(curPrice);
				//alert(amtToBeIncreased);

				var newPrice = parseFloat(curPrice)+parseFloat(amtToBeIncreased);

				if ($priceFull.length > 0) {
					$priceFull.text(formatPrice(newPrice));
				}

				if ($priceFullWithoutcurrency.length > 0) {
					$priceFullWithoutcurrency.text(formatPrice(newPrice).replace('$', ''));
				}

            }

		}

    function refreshLidConfigurationPrices() {
      $lidConfigurationRadio.each((index, elem) => {
        var smallPrice = $(elem).data('optionValueSmall'),
            largePrice = $(elem).data('optionValueLarge'),
            threshold = $(elem).data('optionValueThreshold');
        var strong = $(elem).next().find('strong');
        var newPrice = formatPrice(parseInt($currentSize) >= threshold ? largePrice : smallPrice );
        console.log("Updating lid price", newPrice, "for", $currentSize);
        strong.text(newPrice);
      })
    }



		function getCurrentPrice() {
           // console.log("calling get current price");
			//var $priceFull = $('[data-variant-price-full]');
			//var $priceSale = $('[data-variant-price-sale]');
			var $priceFullWcOrigianl = $('[data-variant-price-full-wc-original]');
            var currentPrice =0;
			if ($priceFullWcOrigianl.length > 0) {
				currentPrice = $priceFullWcOrigianl.text();
			}

			return currentPrice*1;
        }

        function formatPrice(value) {
          return '$' + parseFloat(String(value).replace('$','')).toFixed(2);
        }

        function trackImageSlider() {
            //console.log("Slide event!", $swiperTop.activeIndex);
            if ($swiperTop.activeIndex) {
                var $variantOptions = $variantSelect.find('option');
                var currentVariant = $variantOptions[$swiperTop.activeIndex];
                if (currentVariant) {
                    var size = $(currentVariant).data('size'),
                        material = $(currentVariant).data('material');

                    console.log(size, material);
                    if (size && material) {
                        $currentSize = size;
                        $currentMaterial = material;
                        materialChangeHandler();
                        sizeChangeHandler();
                        selectVariant($currentMaterial, $currentSize);
                        refreshLidConfigurationPrices();
                    }

                }
            }
        }

        if ($swiperTop) {
            $swiperTop.on('slideChange', trackImageSlider);
        }

        console.log("Product handling loaded")
    });
}
